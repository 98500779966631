.detailItem {
    @apply tracking-tight;

    display: flex;
    align-items: flex-start;
    margin-bottom: .75rem;

    &:last-child {
        margin: 0;
    }

    .icnBox {
        width: 1rem;
        text-align: center;
        color: theme("colors.slate.500");
        margin-left: .5rem;
    }

    .title {
        font-weight: bold;
        display: inline;
        margin-left: .25rem;
    }

    .val {
        display: inline;
    }
}

.checkbox {
    cursor: pointer;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    appearance: none;
    border: 1px solid #525252;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: background .2s;

    &::after {
        font-family: "Font Awesome 6 Pro";
        content: "\f00c";
        font-size: .5rem;
        transform: translateY(100%);
        transition: transform .2s;
    }

    &:checked {
        background: #C26F14;
        border-color: #C26F14;

        &::after {
            transform: translateY(0);
        }
    }
}