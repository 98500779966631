@keyframes skeleton-box {
    100% {
        transform: translateX(100%);
    }
}

.skeleton-box {
    position: relative;
    overflow: hidden;
}

.skeleton-box span {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    /* Enable animation */
    background-color: var(--base-color);
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    display: block;
    line-height: 1;
    overflow: hidden;
    position: relative;
    /* Necessary for overflow: hidden to work correctly in Safari */
}

.dark .skeleton-box span {
    --base-color: #2f2f41;
    --highlight-color: #3b3b50;
}

.skeleton-box span::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
    transform: translateX(-100%);
    animation-name: skeleton-box;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}