.marker {
    $width: 30px;
    $height: 45px;

    background: url("./marker.svg");
    position: absolute;
    z-index: 12;
    width: $width;
    height: $height;
    right: calc((100% - $width) / 2);
    top: calc((100% - $height) / 2);
}

.searchbar {
    $p-size: 1rem;

    width: calc(100% - ($p-size * 2));
    position: absolute;
    top: $p-size;
    right: $p-size;
    z-index: 13;

    .results {
        width: 100%;
        background: #fff;
        border-radius: .75rem;
        margin-top: .5rem;
        overflow: hidden;

        .result {
            font-size: .875rem;
            padding: .5rem 1rem;
            white-space: nowrap;
            display: flex;
            align-items: center;

            &::before {
                font-family: "Font Awesome 6 Pro";
                content: "\f3c5";
                color: #979797;
                margin-left: .5rem;
            }

            &:hover {
                background: #fbfbfb;
                cursor: pointer;
            }
        }
    }
}