.error {
    font-size: .75rem;
    color: theme("colors.red.500");
    margin-right: .25rem;
}

.removePass {
    font-size: .813rem;
    background: #e74c3c;
    color: #fff;
    padding: .25rem .5rem;
    transition: background .2s;
    position: relative;
    overflow: hidden;

    &:hover {
        background: #c54234;
    }

    .loading {
        background: #c54234;
    }
}

.radio {
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
    transition: box-shadow .2s;
    cursor: pointer;

    &::after {
        background: theme("colors.primary.100");
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: transform .2s;
        transform: scale(0);
        border-radius: 100%;
    }

    &:checked {
        &::after {
            transform: scale(.7);
        }
    }

    &:focus {
        box-shadow: 0 0 5px rgba(122, 122, 122, .5);
    }

    border-radius: 100%;
}

.disable {
    opacity: .25;

    button {
        cursor: default;
    }
}

.resend {
    border-radius: 10rem;
    padding: 0 1rem;
    font-size: .813rem;
    transition: background .2s;
    position: relative;
    cursor: default;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &.enable {
        cursor: pointer;
    }

    &::before {
        content: "\f2f9";
        font-family: "Font Awesome 6 Pro";
        font-weight: 500;
        margin-left: .5rem;
        position: relative;
        top: .1rem;
        color: #999;
    }

    span {
        display: inline-block;
        width: 3rem;
        font-size: .75rem;
        margin-right: .25rem;
    }
}