$dir: '../font/iransans';

@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: bold;
	src: url('#{$dir}/eot/IRANSansWeb(FaNum)_Bold.eot');
	src: url('#{$dir}/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),
		 url('#{$dir}/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),
		 url('#{$dir}/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),
		 url('#{$dir}/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 500;
	src: url('#{$dir}/eot/IRANSansWeb(FaNum)_Medium.eot');
	src: url('#{$dir}/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),
		 url('#{$dir}/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),
		 url('#{$dir}/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),
		 url('#{$dir}/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url('#{$dir}/eot/IRANSansWeb(FaNum).eot');
	src: url('#{$dir}/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
		 url('#{$dir}/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),
		 url('#{$dir}/woff/IRANSansWeb(FaNum).woff') format('woff'),
		 url('#{$dir}/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}
