@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'iranSans';
@import 'skeleton';
@import 'pallet';
@import 'loading';

* {
    direction: rtl;
    outline: none;
}

$fontAwesome: "Font Awesome 6 Pro";
$sidemenu-width: 240px;
$header-height: 4.063rem;

:root {
    --toastify-font-family: IRANSans !important
}

.p-toast {
    font-weight: normal;
    font-size: .875rem;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 87.5%;

    @screen md {
        font-size: 100%;
    }
}

%noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

%custom-scrollbar {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-primary-100;
        border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgb(185, 185, 185);
    }
}

.noselect {
    @extend %noselect;
}

body {
    background: #E7EAF6;
    overflow-x: hidden;
}

.dark {
    body {
        background: #262642;
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: '';
}

%userbox-flash-vector {
    &::before {
        border-width: 1px;
        border-style: solid;
        border-color: #E8E8F7;
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        left: 1rem;
        top: -.3rem;
        transform: rotate(45deg);
        background: #fff;
    }
}

.dark %userbox-flash-vector {
    &::before {
        background: theme("colors.secondary");
        border-color: #ffffff26;
    }
}

.header {
    $btns-size: 2.5rem;

    height: $header-height;
    right: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    box-shadow: -8px 11px 20px 0 hsl(244deg 8% 59% / 9%);
    z-index: 21;
    padding-right: 1rem;
    transition: padding-right .3s;

    @screen lg {
        z-index: 19;
        padding-right: calc($sidemenu-width + 1rem);

        &.no-pr {
            padding-right: calc(80px + 1rem);
        }
    }

    .fix-size {
        width: calc($btns-size * 2 + .5rem);

        @screen lg {
            width: auto;
        }
    }

    .btn-bar {
        @apply px-1 py-3 text-xl lg:ml-6 text-right leading-none;
        position: relative;

        .box {
            display: block;
            width: 20px;
            height: 14px;

            &::before,
            &::after,
            span {
                display: block;
                height: 2px;
            }

            &::before,
            &::after {
                content: "";
                width: 100%;
            }

            span {
                width: 15px;
                margin: 4px 0;
            }
        }
    }

    .center-logo {
        .l-c1 {
            @apply block dark:hidden;
        }

        .l-c2 {
            @apply hidden dark:block;
        }
    }

    .searchbox {
        top: calc($header-height * 2);
        width: 90%;
        right: 5%;
        box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);

        @screen lg {
            top: 0;
            max-width: 455px;
            width: auto;
            right: 0;
            height: 2.5rem;
            box-shadow: none;
        }

        .cats {
            border-radius: .1rem;

            @screen lg {
                width: 160px;
                border-radius: 0 .5rem .5rem 0;
            }

            &.off-radius {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            .dropdown {
                width: calc(100% + 2px);
                overflow-y: auto;
                border-radius: 0 0 .5rem .5rem;

                li {
                    margin: .2rem 0;
                    padding: .3rem .5rem;
                    transition: all .2s;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }

        .result-box {
            top: calc(100% + 5px);
        }

        .go-btn {
            width: 3rem;
            min-width: 3rem;
            border-radius: .2rem 0 0 .2rem !important;
            height: 100% !important;

            @screen lg {
                border-radius: .5rem 0 0 .5rem !important;
                width: 2.5rem;
                min-width: 2.5rem;
            }
        }
    }

    .btns-box-c1 {
        align-items: center;
        flex-grow: 1;
        position: fixed;
        width: 100%;
        justify-content: flex-end;
        padding: 0 1rem;
        left: 0;
        top: $header-height;
        height: $header-height;
        border-bottom-width: 1px;
        border-style: solid;
        box-shadow: -8px 11px 20px 0 hsl(244deg 8% 59% / 9%);

        @screen lg {
            position: relative;
            width: auto;
            padding: 0;
            background: transparent;
            top: 0;
            border-width: 0;
            box-shadow: none;
        }
    }

    .btns {
        button {
            min-width: $btns-size;
            width: $btns-size;
            height: $btns-size;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            font-size: 1.25rem;
            transition: all .2s;
        }

        .toggle-btns {
            @screen lg {
                display: none;
            }
        }
    }

    .userbox {
        .ui-box {
            min-width: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
        }

        .dropdown {
            @apply bg-white dark:bg-secondary border-b;
            box-shadow: rgba(21, 21, 62, 0.3) -8px 12px 18px 0px;
            border-color: #E8E8F7;

            width: 230px;
            top: 3.3rem;

            @extend %userbox-flash-vector;

            li {
                .c-c1 {
                    width: 100%;
                    height: auto;
                    min-width: auto;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-color: #E8E8F7;
                    color: #6259CA;

                    &:hover {
                        background: #F1F2F9;
                    }

                    transition: background .2s;
                    padding: .5rem 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 0;
                    font-size: .875rem;

                    .icn-box {
                        width: 1.5rem;
                        display: block;
                        font-size: 1rem;
                    }
                }

                &:first-child .c-c1 {
                    @apply border-t
                }

                &:last-child .c-c1 {
                    @apply border-0
                }
            }
        }
    }

    .noti {
        .dropdown {
            @apply bg-white dark:bg-secondary border;
            border-color: #E8E8F7;
            box-shadow: rgba(21, 21, 62, 0.3) -8px 12px 18px 0px;
            width: 300px;
            top: 3.25rem;

            @extend %userbox-flash-vector;

            .noti-item {
                @apply border-b;
                border-color: #F2ECFB;
                $image-width: 2.25rem;
                padding: .5rem 1rem;
                transition: background .2s;
                cursor: pointer;

                &:hover {
                    background: #F1F2F9;
                }

                img {
                    width: $image-width;
                    height: $image-width;
                    border-radius: .2rem;
                    float: right;
                    margin-top: .3rem;
                }

                .col-left {
                    width: calc(100% - $image-width);
                    padding-right: 1rem;
                    float: right;

                    p {
                        color: #444469;
                        font-size: .813rem;
                        line-height: 1.3rem;
                        max-height: 2.6rem;
                        overflow: hidden;
                        font-weight: 500;
                    }

                    span {
                        color: #A8AFC7;
                        font-size: .813rem;
                    }
                }

                &::after {
                    display: block;
                    content: "";
                    clear: both;
                }

                &:last-child {
                    border: 0;
                }
            }
        }
    }
}

.dark .header {
    .userbox .dropdown {
        border-color: #ffffff26;

        li a {
            border-color: #323244;
            color: #D0D0E2;

            &:hover {
                background: #24243E;
            }
        }
    }

    .noti {
        .dropdown {
            border-color: #ffffff26;

            .noti-item {
                border-color: #323244;

                &:hover {
                    background: #24243E;
                }

                .col-left {

                    p,
                    span {
                        color: #D0D0E2;
                    }
                }
            }
        }
    }
}

.light {
    .header {
        .center-logo {
            .l-c1 {
                display: block !important;
            }

            .l-c2 {
                display: none !important;
            }
        }
    }
}

.primary-c {
    .header {
        .center-logo {
            .l-c1 {
                display: none !important;
            }

            .l-c2 {
                display: block !important;
            }
        }
    }
}

.dark {
    .header {
        .center-logo {
            .l-c1 {
                display: none !important;
            }

            .l-c2 {
                display: block !important;
            }
        }
    }
}

.rotate-i {
    transition: transform .2s;
}

.pos-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.fit-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.spec-sb-c1 {
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}

.anim-c1 {
    transition: transform .2s;
    transform: translateY(10%);

    &.enter-active,
    &.enter-done {
        transform: translateY(0);
    }
}

.anim-c2 {
    display: none;
    transition: transform .2s;
    transform: translateY(1rem);

    &.enter {
        display: block;
    }

    &.enter-active,
    &.enter-done {
        transform: translateY(0);
        display: block;
    }
}

.spin {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.setting-modal {
    $width: 260px;
    z-index: 22;
    overflow: auto;

    width: $width;
    left: -$width;
    transition: left .3s;
    box-shadow: 0 8px 14.72px 1.28px hsla(240, 2%, 52%, .2);

    &.enter-active,
    &.enter-done {
        left: 0;
    }

    .cat {
        font-weight: bold;
        padding: .6rem 1rem;
        font-size: .875rem;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .4rem 1rem;
        margin: .3rem 0;
        cursor: pointer;

        span {
            font-size: .875rem;
        }
    }
}

.switch {
    @apply bg-white dark:bg-secondary;
    border: 1px solid #D0D0E2;
    position: relative;
    cursor: pointer;
    transition: all .3s;

    input {
        display: none;
    }

    &::before {
        @apply bg-white dark:bg-secondary;

        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        border: 1px solid #D0D0E2;
        display: block;
        position: absolute;
        right: -1px;
        top: -1px;
        transition: transform .3s;
    }

    display: block;
    width: 2rem;
    height: 1rem;
    border-radius: 2rem;

    &.active {
        @apply bg-primary-100 border-primary-100;

        &::before {
            transform: translateX(calc(-1rem - 1px));
        }
    }
}

.light {
    .switch {
        @apply bg-white;

        &::before {
            @apply bg-white;
        }

        &.active {
            @apply bg-primary-100;
        }
    }
}

.badge-c1 {
    background: #F16D75;
    position: absolute;
    top: .1rem;
    right: .1rem;
    border-radius: 100%;
    color: #FEF8F8;
    font-size: .75rem;
    min-width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.anim-c3 {
    display: none;
    opacity: 0;

    &.enter {
        display: flex;
        transition: opacity .2s;
    }

    &.enter-active,
    &.enter-done {
        display: flex;
        opacity: 1;
    }

    &.exit {
        transition: opacity .2s;
    }

    @screen lg {
        display: flex !important;
        opacity: 1 !important;
    }
}

.sidemenu {
    .wrapper {
        @apply bg-secondary;
        width: $sidemenu-width;
        position: fixed;
        right: -$sidemenu-width;
        padding-top: $header-height;
        height: 100vh;
        z-index: 20;
        transition: right .3s, width .3s;
        display: flex;
        flex-flow: column;
        top: 0;

        @screen lg {
            top: 0;
            right: 0;
            padding-top: 0;
        }
    }

    &.sm-action {
        .wrapper {
            right: 0;
        }
    }

    .logo {
        width: 100%;
        min-height: $header-height;
        height: $header-height;
        background: theme("colors.secondary");
        position: relative;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: #323244;

        &::after {
            content: "";
            position: absolute;
            left: -1px;
            top: 0;
            background: #E8E8F7;
            width: 1px;
            height: calc(100% + 1px);
        }

        .img-c2 {
            display: none;
        }

        &.light-1 {
            background: #fff;

            .img-c1 {
                display: none !important;
            }

            .img-c2 {
                display: block !important;
            }
        }

        &.primary-c-1 {
            background: theme("colors.primary.100");
        }
    }

    &.light {
        .wrapper {
            background: #fff;
        }

        .logo {
            border-color: #E8E8F7;
        }
    }

    &.primary-c {
        .wrapper {
            background: theme("colors.primary.100");
        }

        .logo {
            border-color: #817AD5;
        }
    }

    .routes {
        overflow-y: auto;
        flex-grow: 1;
    }

    .sp-1 {
        @extend %noselect;
        display: flex;
        align-items: center;
        padding: .5rem 0 .5rem 1rem;
        margin-right: 1rem;
        font-size: 0.938rem;
        cursor: pointer;
        transition: color .3s;

        .icn-box {
            min-width: 2.25rem;
            width: 2.25rem;
            height: 2.25rem;
            text-align: center;
            display: flex;
            transition: margin .3s;
            align-items: center;
            margin-left: .5rem;
            justify-content: center;
            border-radius: 100%;

            i {
                font-size: 1.125rem;
            }
        }

        &:hover {
            .icn-box {
                margin-left: .8rem;
            }
        }

        &:active {
            .icn-box {
                margin-left: 0;
            }
        }
    }

    .active-c1 {
        margin-top: 1rem;

        .icn-box {
            background: theme("colors.primary.100");
            box-shadow: -5px 5px 10px rgba(0, 0, 0, .2);
            color: #fff;
        }

        .tp-16 {
            padding-right: .5rem;
            border-radius: 0 10rem 10rem 0;
            position: relative;
            margin-bottom: 1rem;

            &::before {
                content: "";
                width: 1.25rem;
                height: 1.5rem;
                position: absolute;
                top: -1.5rem;
                left: 0;
                border-radius: 0 0 0 48px;
                background: theme("colors.secondary");
                z-index: 5;
            }

            &::after {
                content: "";
                width: 1.25rem;
                height: 1.5rem;
                position: absolute;
                bottom: -1.5rem;
                left: 0;
                border-radius: 48px 0 0 0;
                background: theme("colors.secondary");
                z-index: 5;
            }
        }

        %cover-shape {
            position: absolute;
            width: 1.25rem;
            height: 2rem;
            left: 0;
            z-index: 4
        }

        .t-shape {
            @extend %cover-shape;
            top: -1rem;
        }

        .b-shape {
            @extend %cover-shape;
            bottom: -1rem;
        }
    }

    span.sp-1 {
        .add-lf {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            position: relative;

            &::after {
                content: "\f104";
                font-family: $fontAwesome;
                font-weight: 400;
                font-size: .75rem;
                transition: transform .3s;
                position: absolute;
                left: .5rem;
                top: .2rem;
            }
        }
    }

    li {
        transition: margin .1s;

        &:first-child.active-c1 {
            margin-top: 2rem;
        }

        &.open {
            .add-lf {
                &::after {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    &.dark .active-c1 .tp-16 {

        &::before,
        &:after {
            background: theme("colors.secondary");
        }
    }

    &.light .active-c1 .tp-16 {

        &::before,
        &:after {
            background: #fff;
        }
    }

    &.primary-c .active-c1 .tp-16 {

        &::before,
        &:after {
            background: theme("colors.primary.100");
        }
    }

    .childs {
        a {
            @extend %noselect;
            padding: .3rem 1.5rem .3rem .5rem;
            font-size: .875rem;
            margin: .1rem 0;
            display: block;
            transition: color .2s;
            white-space: nowrap;
            overflow: hidden;

            &::before {
                content: "\f104";
                font-weight: 600;
                font-size: .7rem;
                font-family: $fontAwesome;
                margin-left: 1rem;
            }
        }
    }

    @screen lg {
        &.lg-action {
            .add-lf {
                display: none;
            }

            .wrapper {
                width: 80px;
            }

            .icn-box {
                margin-left: 0;
                box-shadow: none;
            }

            .childs {
                display: none;
            }

            .logo {
                .text-img {
                    display: none !important;
                }
            }

            &:hover {
                .add-lf {
                    display: block;
                }

                .wrapper {
                    width: calc($sidemenu-width - 1px);
                }

                .icn-box {
                    margin-left: .5rem;
                }

                .sp-1 {
                    display: flex
                }

                .childs {
                    display: block;
                }
            }
        }
    }
}

.collapse-anim {
    transition: height 300ms;
}

.dark .sidemenu .logo::after {
    background: #323244;
    border-color: #323244;
}

.force-bc-1::after {
    background: #E8E8F7 !important;
}

.force-bc-2::after {
    background: #323244 !important;
}

.force-bc-3::after {
    display: none;
}

.force-bc-4::after {
    background: #817AD5 !important;
}

.p-content {
    padding: calc($header-height + 2rem) 0 2rem 0;
    transition: padding .2s;

    @screen lg {
        padding: calc($header-height + 2rem) $sidemenu-width 2rem 0;
    }

    &.lg-action {
        @screen lg {
            padding-right: 80px;
        }
    }

    .m-container {
        padding: 0 1.5rem;
    }

    .wrap-box {
        border-radius: .5rem;
        padding: 1rem;

        &.no-p {
            padding: 0;
        }
    }
}

.title-c1 {
    font-size: 1.25rem;
    font-weight: 500;
}

.breadcrumb {
    li {
        display: inline-block;
        font-size: .875rem;
        padding-left: .6rem;
        margin-left: .3rem;
        position: relative;

        span {
            cursor: default;
        }

        a {
            transition: color .2s;
        }

        a,
        span {
            padding: .2rem;
            display: block;
        }

        &:first-child {

            a,
            span {
                padding-right: 0;
            }
        }

        &::after {
            content: "/";
            position: absolute;
            left: 0;
            top: .2rem;
        }

        &:last-child::after {
            content: "";
        }
    }
}

.selectbox {
    font-size: .875rem;
    position: relative;

    li {
        position: relative;
    }

    .box {
        cursor: pointer;
        position: relative;
        padding: 0 .5rem 0 2.5rem;
        border-radius: .4rem;

        &::after {
            content: "\f078";
            font-family: $fontAwesome;
            margin-right: 1rem;
            transition: transform .2s;
            display: block;
            position: absolute;
            top: .6rem;
            left: .5rem;
            font-size: .75rem;
        }

        &.opn {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .toggle-box {
        position: absolute;
        width: 100%;
        border-radius: .5rem;
        overflow: hidden;
        z-index: 1;

        span,
        a {
            display: block;
            cursor: pointer;
            padding: .4rem .8rem;
        }
    }

    &.c1 {
        .toggle-box {
            top: 3rem;
        }

        .box {
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
        }
    }

    &.c2 {
        .box {
            @apply rounded-md;
            background: theme("colors.primary.100");
            color: #fff;
            height: 100%;
            display: flex;
            align-items: center;

            &::before {
                content: "\f65e";
                font-family: $fontAwesome;
                font-weight: 300;
                margin-left: .5rem;
            }

            &::after {
                margin-right: .5rem;
                top: .7rem;
            }
        }

        .toggle-box {
            @extend %custom-scrollbar;

            top: 3rem;
            max-height: 10rem;
            overflow-y: auto;
        }
    }

    &.c3 {
        .box {
            padding: .5rem;
        }

        .toggle-box {
            top: 3.5rem;
        }
    }
}

.options-holder {
    button {
        border-radius: .4rem;
        margin-right: .25rem;
    }

    li {
        text-align: center;
    }
}

.table-c1 {
    table {
        width: 100%;

        td {
            padding: .75rem;
        }
    }

    .status {
        &.active {
            color: #7fba00;
        }

        &.deactive {
            color: theme("colors.red.500");
        }
    }

    .desc-col {
        padding: 0;

        .content {
            position: relative;
            padding: .75rem;
        }

        p {
            font-size: .875rem;
        }

        h6 {
            font-size: .813rem;
        }

        &.has-icn {
            display: flex;
            align-items: stretch;
        }
    }

    .priority-col {
        text-align: center;
    }

    .noti {
        background: #01B8FF;
        color: #fff;
        font-weight: 500;
        border-radius: .3rem;
        padding: 0 .5rem;
        font-size: .875rem;
        white-space: nowrap;
        cursor: pointer;

        &::before {
            content: "\f0dc";
            font-family: $fontAwesome;
            font-weight: 900;
            position: relative;
            top: 1px;
        }

        span {
            margin-left: .3rem;
        }
    }
}

.p-checkbox {
    input {
        display: none;
    }

    span {
        border-width: 1px;
        border-style: solid;
        width: 18px;
        height: 18px;
        border-radius: .2rem;
        display: block;
        cursor: pointer;
        transition: background .1s;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "\f00c";
            color: #fff;
            font-family: $fontAwesome;
            font-size: .7rem;
            font-weight: 600;
            transform: scale(0);
            transition: transform .2s;
        }
    }

    &.checked {
        span {
            background: theme("colors.primary.100");

            &::after {
                transform: scale(1);
            }
        }
    }

    &.x-type.checked {
        span {
            background: theme("colors.red.500");

            &::after {
                content: "\f00d"
            }
        }
    }
}

.pagination-c1 {
    display: flex;
    font-size: .8575rem;
    border-radius: .5rem;
    overflow: hidden;

    li {
        .in-sec {
            display: block;
            padding: .5rem .8rem;
            transition: all .2s;
            cursor: pointer;
        }

        &.deactive {
            .in-sec {
                cursor: default;
            }
        }

        &:last-child {
            border: none;
        }
    }
}

.lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity .2s;
    opacity: 0;
    z-index: 99;

    &.enter-done {
        opacity: .5;
    }
}

.modal {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @screen md {
        align-items: center;
    }

    .close-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .wrapper {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        transition: opacity .2s;
        max-height: 90vh;
        display: flex;
        flex-direction: column;
        padding: .7rem 1rem;
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
        animation: 0.3s cubic-bezier(0.4, 0, 0, 1.5) 0s 1 normal forwards running ModalWrap;
        opacity: 0;

        @screen md {
            transform: scale(0);
            border-radius: .5rem;
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: .3rem;
            margin-bottom: 1rem;
            color: #222;

            b {
                font-weight: 500;
            }

            button {
                padding: .5rem .5rem .5rem 0;
            }
        }

        .content {
            flex-grow: 1;
            overflow: auto;
        }
    }

    &.enter-active .wrapper,
    &.enter-done .wrapper {
        opacity: 1;
    }

    &.designModal {
        .wrapper {
            max-width: 95%;
            height: 90vh;
        }

        .content {
            overflow: hidden;
        }
    }

    &.addressModal {
        .wrapper {
            height: 90vh;
        }
    }
}

@keyframes ModalWrap {
    0% {
        transform: translate3d(0px, 2rem, 0px);
    }

    100% {
        transform: translateZ(0px);
    }
}

.text-field {
    position: relative;

    input {
        display: block;
        width: 100%;
        border-radius: 100rem;
        font-size: .875rem;
        padding: .7rem 1rem;
        transition: all .2s;
    }

    label {
        position: absolute;
        right: .6rem;
        font-size: .875rem;
        top: .65rem;
        padding: 0 .5rem;
        transition: font-size .2s, top .2s;
        cursor: text;
    }

    &:focus-within,
    &.has-val {
        label {
            top: -.7rem;
            font-size: .813rem;
        }
    }
}

.search-modal .content {
    padding: .5rem 1px;
}

.checkbox-holder {
    font-size: .875rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    label {
        margin-left: .5rem;
    }
}

.select-c1 {
    border: 1px solid #dadada;
    border-radius: 10rem;
    font-size: .875rem;
    display: block;
    padding: 0 0 0 1rem;
    cursor: pointer;
    position: relative;

    select {
        width: 100%;
        padding: .6rem 1rem .6rem 0;
        cursor: pointer;
        border-radius: 10rem;
    }

    span {
        position: absolute;
        right: 1rem;
        top: -.7rem;
        background: #fff;
        padding: 0 .5rem;
        font-size: .813rem;
        color: #555;
    }
}

.radio-c1 {
    font-size: .875rem;

    input {
        display: none;
        width: 1.125rem;
        height: 1.125rem;
        display: inline-block;
        border-radius: 100%;
        appearance: none;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        top: .4rem;
        margin-left: .5rem;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: theme("colors.primary.100");
            transform: scale(0);
            border-radius: 100%;
            transition: transform .2s;
        }

        &:checked::after {
            transform: scale(.6);
        }
    }
}

.cat-holder {
    border-radius: .5rem;
    position: relative;
    padding: 1rem;
    margin-top: .5rem;

    .label {
        position: absolute;
        top: -.75rem;
        font-size: .875rem;
        right: .5rem;
        padding: 0 .5rem;
    }
}

.datepicker-c {
    display: block !important;
}

.unselect-c1 {
    position: absolute;
    left: .5rem;
    top: .37rem;
    padding: .3rem .5rem;

    &::before {
        content: "\f00d";
        font-family: $fontAwesome;
    }
}

.uploader {
    font-size: .875rem;

    .file-field {
        display: flex;
        border-radius: 100rem;
        padding: .7rem 1rem;
        cursor: pointer;
        align-items: center;

        input {
            display: none;
        }

        i {
            font-size: 1.25rem;
        }
    }

    ul {
        margin: .5rem .5rem 0 0;
    }
}

.fu-item {
    border-radius: .3rem;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    margin: 0 0 .5rem 1rem;
    cursor: grab;
    font-size: .875rem;
    transition: box-shadow .4s;

    &.no-grab {
        cursor: default;
    }

    a {
        display: block;
        align-items: center;

        &::after {
            content: "\f0c1";
            font-family: $fontAwesome;
            display: block;
            display: flex;
            align-items: center;
            padding: .5rem;
            margin-left: 2px;
        }
    }

    .sp-c1 {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .sp-c2 {
        &::after {
            content: "."
        }
    }

    button.del {
        transition: background .2s;
        display: flex;
        align-items: center;

        &::before {
            content: "\f1f8";
            font-family: $fontAwesome;
            padding: 0 .5rem;
            font-weight: 600;
        }
    }
}

.fu-item-c1 {
    border-radius: .3rem;
    display: block;
    align-items: stretch;
    overflow: hidden;
    margin: 0 0 .5rem 0;
    cursor: grab;
    font-size: .875rem;
    transition: box-shadow .4s;
}

.textarea-c1 {
    border-radius: 2rem;
    font-size: .875rem;
    padding: .7rem 1rem;
    position: relative;
    display: block;
    width: 100%;
    cursor: text;
    transition: all .2s;

    textarea {
        display: block;
        width: 100%;
        resize: none;
    }

    label.title {
        position: absolute;
        right: .6rem;
        font-size: .875rem;
        top: .65rem;
        padding: 0 .5rem !important;
        transition: font-size .2s, top .2s;
        border: 0 !important;
    }

    &:focus-within,
    &.has-val {
        label.title {
            top: -.7rem;
            font-size: .813rem;
        }
    }

    &.editor-box {
        padding: 1rem;
        border-radius: 1rem;
    }

    &.low-br {
        border-radius: .5rem;
    }
}

.anim-c4 {
    opacity: 0;
    transition: opacity .2s;

    &.enter-active,
    &.enter-done {
        opacity: 1;
    }
}

.anim-c6 {
    transition: opacity .5s;

    &.exit-active,
    &.exit-done {
        opacity: 0;
    }
}

.json-field {
    li {
        display: flex;
        align-items: stretch;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        button {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            border-radius: .5rem;
            padding: 0 1rem;
            border-radius: .5rem;
            transition: background .2s;

            &.add {
                background: theme("colors.primary.100");

                &:hover {
                    background: theme("colors.primary.200");
                }
            }

            &.del {
                background: theme("colors.red.500");

                &:hover {
                    background: theme("colors.red.600");
                }
            }
        }

        .text-field {
            flex-grow: 1;
            margin-left: 1rem;
        }
    }
}

.anim-c5 {
    opacity: 0;
    transition: opacity .2s;

    &.enter-active,
    &.enter-done {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 1s;
}

.fade-in-short {
    animation: fadeIn .5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.icn-holder-c1 {
    display: flex;
    align-items: center;
}

.dir-ltr {
    direction: ltr !important;
}

.form-ltr {
    input {
        direction: ltr !important;
        text-align: left;
    }
}

.dir-rtl {
    direction: rtl !important;
}

.search-select {
    .label-c {
        position: absolute;
        right: 1rem;
        top: -.7rem;
        padding: 0 .5rem;
        font-size: .813rem;
        z-index: 1;
    }
}

.tree-holder {
    ol {
        padding-right: 1.5rem;
        font-size: .875rem;

        &:first-child {
            padding-right: 0
        }
    }

    .rct-title {
        @extend %noselect;
    }
}

.section-title {
    position: relative;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        top: calc((100% - 1px) / 2);
        right: 0;
    }

    b {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        padding: 0 1rem;
        font-weight: 500;
        font-size: .875rem;
    }
}

.f-error {
    color: theme("colors.red.600");
    font-size: .75rem;
    margin: .1rem .5rem 0 0;
    display: block;
}

.fi-error {
    input {
        border-color: theme("colors.red.300");
    }
}

.select-form-lightbox {
    z-index: 10;
    backdrop-filter: blur(5px);
    padding: 2rem;

    .m-block {
        border-radius: 10rem;
        margin-bottom: 1rem;
        background: theme("colors.secondary");

        a {
            display: flex;
            align-items: center;
            padding: .5rem;
            color: #fff;
        }

        span {
            width: 2rem;
            height: 2rem;
            display: block;
            background: #fff;
            color: theme("colors.secondary");
            border-radius: 10rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
        }
    }
}

.control-item {
    margin-bottom: 1rem;
    cursor: grab;

    &:last-child {
        margin-bottom: 0;
    }

    .inner {
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
        border-radius: .5rem;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding-left: 1rem;

        button {
            width: 2rem;
            height: 2rem;
            display: block !important;
            background: #eee;
            border-radius: 100%;
            margin-right: .5rem;
        }

        &.drag {
            background: #fbfbfb;
            cursor: grabbing;
        }

        span.sp-1 {
            padding: .75rem;
        }
    }
}

.auth {
    .content-box {
        width: 90%;
        min-height: 100vh;
        margin: 0 auto;
        padding: 5rem 0;

        @screen md {
            width: 75%;
        }

        @screen lg {
            width: 60%;
        }

        @screen xl {
            width: 50%;
        }

        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .col-r {
        background: #6259CA;
        width: 35%;
        display: none;

        @screen md {
            display: block;
        }

        .sp-1 {
            color: rgba(255, 255, 255, .6);
        }
    }

    .col-l {
        width: 100%;

        @screen md {
            width: 65%;
        }

        .go-btn {
            background: #6259CA;
            text-align: center;
            color: #fff;
            width: 100%;
            font-size: .875rem;
            border-radius: 5rem;
            padding: .75rem;
            overflow: hidden;
            margin-top: 1rem;
            transition: background .3s;
            position: relative;

            &:hover {
                background: #564db3;
            }
        }
    }
}

.cm-holder {
    * {
        direction: ltr;
    }
}

.confirm {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 101;
    padding: 0 1rem;
    transform: translateY(-100%);

    &.enter {
        transition: transform .3s;
    }

    &.exit {
        transition: transform .1s;
    }

    &.enter-active,
    &.enter-done {
        transform: translateY(0);
    }

    .wrap-box {
        max-width: 30rem;
        margin: 0 auto;
        padding: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        font-size: .875rem;

        p {
            font-weight: 500;
        }

        button {
            border-radius: .5rem;
            transition: background .3s;
        }
    }
}

.order-detail {
    font-size: .875rem;

    li {
        &::after {
            width: .25rem;
            height: .25rem;
            border-radius: 100%;
            content: "";
            display: inline-block;
            margin: 0 .75rem;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        span {
            margin-left: .5rem;
            display: inline-block;
        }

        b {
            font-weight: 500;
        }
    }
}

.splash-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: theme("colors.primary.100");
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .5s;

    &.exit-active,
    &.exit-done {
        opacity: 0;
    }

    .version {
        position: absolute;
        bottom: 1rem;
    }

    .error {
        text-align: center;

        b {
            color: #fff;
            display: block;
            margin-bottom: .25rem;
        }

        span {
            color: #ccc;
        }
    }
}

.tickets {
    .send {
        border-radius: .5rem;
        width: 100%;
        padding: 1rem 1rem 1rem 0;
        display: flex;
        align-items: stretch;
        transition: all .3s;
        box-shadow: 0 0 0 2px rgba(132, 196, 255, .7);

        &:focus-within {
            box-shadow: 0 0 0 2px rgb(132, 196, 255);
            border-color: rgb(132, 196, 255);
        }

        textarea {
            outline: none;
            resize: none;
            border: 0;
            flex-grow: 1;
            font-size: .875rem;
            background: transparent;
        }

        button {
            align-self: flex-end;
            padding: 0 1rem;
            color: #333;
            transition: color .2s;

            &:hover {
                color: #555;
            }
        }
    }

    .ticket {
        border: 1px solid #abf1f1;
        border-radius: .5rem;
        padding: 1rem;
        margin-bottom: 1rem;
        position: relative;
        transition: border .2s;

        &:last-child {
            margin: 0;
        }

        &.hoverAction {
            &:hover {
                border-color: #777;
            }
        }

        .title {
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            font-size: .875rem;

            &.hasBB {
                border-bottom: 1px solid #abf1f1;
                margin-bottom: 1rem;
            }
        }

        .time {
            display: flex;
            align-items: center;
            font-size: .875rem;
            direction: ltr;

            .line {
                background: #abf1f1;
                width: 1px;
                height: 1rem;
                margin: 0 .5rem;
                display: block;
            }
        }

        p {
            font-size: .875rem;
            color: #000;
            margin: 0;
            padding: 0;
        }
    }
}

.fc-blocks {
    li {
        display: flex;
        justify-content: space-between;
        padding: 0;

        @screen lg {
            padding: 0 10rem;
        }

        @screen xl {
            padding: 0 10rem;
        }
    }

    span {
        margin-left: .25rem;
    }
}

.factor-sum {
    .unit {
        font-size: .75rem;
        font-weight: normal;
    }

    ul {
        li {
            display: flex;

            &>* {
                width: 50%;
                padding: .5rem;
            }

            span {
                font-size: .875rem;
            }
        }
    }
}

.price-table {
    table {
        width: 100%;
    }

    .main-tbl {
        tr {
            border-bottom: 1px;
            border-style: solid;
            padding: 1rem 0;

            &>td {
                padding: .5rem;
                text-align: center;
                font-size: .87rem;
            }
        }

        tbody tr:last-child {
            border-bottom: 0;
        }

        thead {
            font-size: .875rem;

            th {
                font-weight: 500;
                padding: 1rem .5rem;
            }
        }

        .bb-0 {
            border: 0;
            padding: 0;
        }
    }

    .img-box {
        width: 4rem;
        height: 4rem;
        border-width: 1px;
        border-style: solid;
        margin: 0 auto;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .25rem;
        }

        .im-status {
            width: 1.5rem;
            height: 1.5rem;
            display: block;
            position: absolute;
            left: -.5rem;
            top: -.5rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .75rem;
        }
    }

    .sp-1 {
        font-weight: bold;
    }

    .title {
        text-align: right;
        border-radius: 10rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;

        a {
            padding: .5rem 0;
            display: block;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        .discount {
            font-size: .75rem;
            border-radius: 10rem;
            padding: .1rem .25rem;
            display: inline-block;
            margin-right: .5rem;
            white-space: nowrap;
            font-weight: normal;
        }
    }

    .switch-status {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 1rem;

        .lbl {
            font-size: .75rem;
        }
    }
}

.input-number {
    display: inline-flex;
    align-items: center;
    border-radius: .2rem;
    overflow: hidden;

    span {
        width: 2rem;
        height: 2rem;
        font-size: .75rem;
        padding-top: .2rem;
        transition: background .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    input {
        height: 2rem;
        padding: 0 .5rem;
        text-align: center;
        direction: ltr;
        flex-grow: 1;
        max-width: 6rem;
    }
}

.anim-c7 {
    opacity: 0;
    transition: opacity .2s;

    &.enter-active,
    &.enter-done {
        opacity: .7;
    }
}

.order-detail {
    font-size: .875rem;

    li {
        &::after {
            width: .25rem;
            height: .25rem;
            border-radius: 100%;
            content: "";
            display: inline-block;
            margin: 0 .75rem;
        }

        &:last-child,
        &.no-circle {
            &::after {
                display: none;
            }
        }

        span {
            margin-left: .5rem;
            display: inline-block;
        }

        b {
            font-weight: 500;
        }
    }
}

.reply-textarea {
    font-size: .875rem;
    display: block;
    margin-top: .5rem;
    width: 100%;
    resize: none;
    padding: .5rem;
    border-radius: .5rem;
    transition: box-shadow .2s, border .2s;
}

.dashboard {
    .title {
        font-weight: bold;
        font-size: .875rem;
    }

    .top-msg {
        display: flex;
        background: theme("colors.primary.100");
        border-radius: .5rem;
        position: relative;
        padding: 1rem;

        img {
            position: absolute;
            left: -.25rem;
            bottom: -.125rem;
        }
    }
}

.pre-c1 {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.force-white {
    color: #fff !important;
}

.nch-input {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }
}

.permissions {
    margin-bottom: 2rem;

    .head {
        font-size: .875rem;
        font-weight: 500;
        text-align: center;
        padding: .5rem 0;
        margin-bottom: 1rem;
    }

    .body {
        text-align: center;
    }

    .exp-btn {
        &::before {
            font-family: $fontAwesome;
        }

        &.plus {
            &::before {
                content: "\2b";
            }
        }

        &.minus {
            &::before {
                content: "\f068";
            }
        }
    }

    .hide-btn {
        width: .875rem;
        display: inline-block;
    }

    .childs {
        padding-right: 1.5rem;
    }

    .row {
        display: flex;
        padding: .25rem 0;
    }

    .title-col {
        text-align: right;
        flex-grow: 1;
        padding: 0 .5rem;
        display: flex;
        align-items: center;
        font-size: .875rem;

        .exp-btn,
        .hide-btn {
            margin-left: .5rem;
        }

        input[type="checkbox"] {
            margin-left: 1rem;
        }
    }

    .checked-col {
        min-width: 3.5rem;
        padding: 0 .5rem;
    }

    input[type="checkbox"] {
        appearance: none;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid #ccc;
        border-radius: .2rem;
        cursor: pointer;

        &::before {
            font-family: $fontAwesome;
            content: "\f00c";
            width: 100%;
            height: 100%;
            background: theme("colors.primary.100");
            transform: scale(0);
            border-radius: .2rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .75rem;
            transition: transform .1s;
        }

        &:checked {
            &::before {
                transform: scale(.8);
            }
        }
    }
}

.anim-c8 {
    opacity: 0;

    &.enter {
        transition: opacity .3s;
    }

    &.enter-active,
    &.enter-done {
        opacity: 1;
    }
}

.file-manager {
    .routes {
        li {
            font-weight: 500;
            font-size: .875rem;
            margin-bottom: .5rem;

            &:last-child {
                margin: 0;
            }

            i {
                font-size: 1.125rem;
                position: relative;
                top: .1rem;
                margin-left: .5rem;
            }

            a {
                padding: .75rem .5rem;
                border-radius: .5rem;
                display: block;
                transition: background .2s;
            }
        }
    }

    .dirs {
        li {
            display: flex;
            font-size: .875rem;
            padding: .5rem;
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
            cursor: pointer;
            font-weight: 500;
            transition: color .2s;

            &::before {
                content: "\f07b";
                font-family: $fontAwesome;
                margin-left: .5rem;
            }

            &.active {
                border-left: 0;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    left: -2px;
                    top: 0;
                }
            }
        }
    }

    .files {
        li {
            font-size: .875rem;
            padding: .5rem;
            display: flex;

            a {
                transition: color .2s;
            }
        }
    }
}

.apexcharts-legend-marker {
    margin: 0 0 0 .5rem !important;
}

.apexcharts-yaxis {
    transform: translate(0) !important;
}

.apexcharts-tooltip-marker {
    margin: 0 0 0 5px !important;
}

.apexcharts-tooltip-series-group {
    text-align: right !important;
    justify-content: flex-start !important;
}

.spec-sb-c2 {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-primary-100;
        border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(207, 207, 207);
    }
}

.state-box {
    li {
        display: flex;
        align-items: center;
        padding: .5rem;

        div {
            flex-grow: 1;
        }
    }

    i {
        font-size: 1.125rem;
        position: relative;
        top: .15rem;
        margin-left: .5rem;
    }

    .sec-title {
        font-size: .813rem;
    }
}

.balance-holder {
    .m-block {
        border-radius: .5rem;
        padding: 1rem;

        &.hover-act {
            transition: box-shadow .2s;
        }
    }

    .box-title {
        font-weight: bold;
        font-size: .875rem;
    }

    .icon-box {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125rem;
    }

    li {
        display: flex;
        font-size: .875rem;
        align-items: center;

        &:first-child {
            padding: .5rem 0;
            margin-bottom: .5rem;
        }

        span {
            white-space: nowrap;
            font-size: .75rem;
        }

        b {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            direction: ltr;
            margin: 0 .5rem 0 .25rem;
            font-size: .875rem;
        }
    }
}

.financial-list {
    li {
        display: flex;
        align-items: center;
        font-size: .875rem;

        &:last-child {
            border: 0;
        }

        b {
            margin: 0 .25rem;
            overflow: hidden;
            flex-grow: 1;
            direction: ltr;
            text-overflow: ellipsis;
            text-align: left;
        }

        span {
            white-space: nowrap;
        }
    }
}

.title-c2 {
    font-size: .875rem;
}

.crop-size-block {
    animation: fadeIn .5s;
    transition: box-shadow .5s;

    @screen md {
        padding: 1.5rem 1rem !important;
    }

    &.has-error {
        box-shadow: 0 0 3px 3px rgb(255, 0, 0, .2)
    }

    .local-error {
        font-size: .75rem;
        color: theme("colors.red.500");
        margin: .25rem .5rem 0 0;
    }

    &.low-p {
        padding: 1rem !important;
    }
}

.ltr-input {
    input {
        direction: ltr;
    }
}

.global-error {
    @apply text-red-600 dark:text-red-400;
    font-size: .875em;
}

.user-detail {
    .detail-block {
        @apply mt-3 p-3 border rounded-md border-gray-200 dark:border-slate-800 text-sm;

        b {
            font-weight: 500;
        }

        .title {
            @apply min-w-[10rem] text-gray-400 dark:text-slate-300;

            &.auto-w {
                min-width: auto !important;
            }
        }

        .value {
            @apply grow text-gray-800 dark:text-gray-100;
        }
    }

    .user-info {
        $title-col-width: 100px;
        font-size: .875rem;

        li {
            padding: 1rem 0;
            display: block;

            &::after {
                display: block;
                clear: both;
            }
        }

        .col-t {
            display: block;
            width: $title-col-width;
            float: right;
            color: #8d8d8d;
        }

        .col-v {
            @apply text-slate-800 dark:text-slate-100;

            width: calc(100% - $title-col-width);
            padding-right: 1rem;
            float: right;
            font-weight: 500;
        }
    }
}

.financial-state {
    li {
        display: flex;
        align-items: center;
        padding: .5rem 0;

        .col-sbh {
            flex-grow: 1;
            font-weight: 500;
        }
    }

    i {
        font-size: 1.125rem;
        position: relative;
        top: .15rem;
        margin-left: .5rem;
    }

    .sec-title {
        font-size: .875rem;
    }
}

main {
    transition: all .1s;

    &.enter-active {
        transform: translateY(-20px);
    }

    &.exit-done {
        transform: translateY(0);
    }
}

.spec-thead-c1 {
    th {
        font-weight: 500;
        font-size: .875rem;
        padding-bottom: 1rem;
    }
}

.reset-i {
    i {
        font-size: .875rem;
        top: 0;
        margin: 0 .3rem 0 0;
    }
}

.form-default-tabs {
    position: -webkit-sticky;
    position: sticky;
    top: $header-height;
    z-index: 10;

    .m-block {
        @apply bg-slate-100 dark:bg-slate-800 text-slate-900 dark:text-slate-200;
        padding: .75rem 0;
        transition: background .3s;
        font-size: .875rem;
        border-radius: .25rem;

        &:hover,
        &.active {
            @apply bg-slate-200 dark:bg-slate-700;
        }
    }
}

.compare-error {
    label {
        color: red !important;
    }
}

.loading-c3 {
    background: #000;
    opacity: 0;
    color: #fff;
    transition: opacity .3s;

    &.enter-active,
    &.enter-done {
        opacity: .2;
    }
}

.link-c1 {
    @apply text-blue-800 dark:text-blue-300 hover:text-blue-900 dark:hover:text-blue-400 transition-colors;
}

.cl-result-box {
    position: absolute;
    width: 100%;
    top: 3.5rem;
    border-radius: .5rem;
    padding: .5rem;
    transform: translateY(10px);
    transition: transform .3s;
    z-index: 10;

    .box {
        overflow: auto;
        max-height: 200px;
    }

    &.enter-active,
    &.enter-done {
        transform: translateY(0);
    }

    .lcr-error {
        display: block;
        text-align: center;
        font-size: .875rem;
        margin: .5rem 0;
    }

    li.ls-block {
        font-size: .875rem;
        padding: .5rem;
        transition: background .2s;
        cursor: pointer;
    }
}

.provider_row {
    &::after {
        @apply border-gray-300 dark:border-slate-600;

        content: "";
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        margin: 1rem 0;
        display: block;
    }
}

.loading-c5 div {
    background: theme("colors.primary.100");
}

.autoRequestBtn {
    @extend %noselect;

    font-size: .813rem;
    border-radius: 0.375rem;
    padding: 0 .5rem;
    display: flex;
    align-items: center;

    .circle {
        @apply bg-gray-300 dark:bg-slate-600;

        width: .75rem;
        height: .75rem;
        border-radius: 100%;
        display: block;
        margin-left: .5rem;
        transition: background .3s;
    }

    &.active {
        .circle {
            animation-name: onOff;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}

@keyframes onOff {
    0% {
        background: theme("colors.green.600");
    }

    50% {
        background: theme("colors.gray.300");
    }

    100% {
        background: theme("colors.green.600");
    }
}

.options-bar {
    position: sticky;
    top: 4rem;
    z-index: 18;
    backdrop-filter: blur(5px);
    padding: .5rem;
}

.mobileSlider {
    overflow: auto !important;

    @media only screen and (max-width: 767px) {
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 1rem;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }
    }
}

.page-transition {
    animation: 0.3s pageTransition;
}

@keyframes pageTransition {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.tiny-order {
    &::after {
        display: inline-block;
        content: "";
        width: .25rem;
        height: .25rem;
        border-radius: 100%;
        margin: 0 .5rem;
    }

    &:last-child::after {
        display: none;
    }
}

.filesTbl {
    font-size: .875rem;

    td,
    th {
        padding: 1rem;

        &:last-child {
            border: 0;
        }
    }

    th {
        font-weight: normal;
    }

    tbody tr:last-child {
        border: 0;
    }
}

.cat-c358-el {
    .results-box {
        position: absolute;
        top: calc(100% + .25rem);
        width: 150px;
        background: #fff;
        border-radius: .5rem;
        left: 0;
        z-index: 20;
        display: none;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    }

    .detail-icon:hover {
        .results-box {
            display: block;
        }
    }
}

.cat-c359-el {
    .results-box {
        position: absolute;
        top: calc(100% + .25rem);
        width: 150px;
        background: #fff;
        border-radius: .5rem;
        left: 0;
        z-index: 20;
        display: none;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    }

    &:hover {
        .results-box {
            display: block;
        }
    }
}

.feedback-item-c75 {
    &::after {
        content: " - ";
    }

    &:last-child::after {
        content: "";
    }
}

.field-c1 {
    .label {
        font-size: .875rem;
        font-weight: 900;
        display: block;
        margin-bottom: .5rem;
    }

    input,
    textarea,
    select,
    .box {
        display: block;
        margin: 0;
        font-size: .875rem;
        background: #fbfbfb;
        width: 100%;
        border: 1px solid rgba(194, 194, 194, .2);
        padding: 1rem;
        border-radius: 1rem;
        transition:
            box-shadow .2s,
            background .2s,
            border-color .2s;

        &:focus {
            border-color: transparent;
            box-shadow: 0 0 0 2px theme("colors.primary.100");
            background: #fff;
        }
    }

    .error-msg {
        font-size: .75rem;
        color: theme("colors.red.500");
        display: block;
        margin-top: .25rem;
    }
}

.dark .field-c1 {
    .label {
        color: theme("colors.slate.300");
    }

    input,
    textarea,
    select,
    .box {
        background: theme("colors.slate.900");
        border: 1px solid rgba(194, 194, 194, .2);
        color: #fff;

        &:focus {
            box-shadow: 0 0 0 2px theme("colors.primary.100");
        }
    }

    .error-msg {
        color: theme("colors.red.500");
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}