.tp-1 {
    background: #fff;
}

.dark .tp-1 {
    background: theme("colors.secondary");
}

.tp-2 {
    border-color: #E8E8F7;
}

.dark .tp-2 {
    border-color: #323244;
}

.dark.tp-2 {
    border-color: #323244;
}

.tp-3 {
    color: #BBAFC7;
}

.dark .tp-3 {
    color: #9DA3BB;
}

.tp-4 {
    color: #1D212F;

    &:hover {
        background: #F8F9FF;
        color: #6259CA;
    }
}

.dark .tp-4 {
    color: #D0D0E2;

    &:hover {
        color: #3C46CA;
        background: #2A2A43;
    }
}

@mixin placeholder-style() {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
        opacity: 1;
    }

    &::-moz-placeholder {
        @content;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-ms-input-placeholder {
        @content;
    }

    &::placeholder {
        @content;
    }
}

.tp-5 {
    color: #1D212F;

    @include placeholder-style {
        color: #BBAFCB;
    }
}

.dark .tp-5 {
    color: #D0D0E2;

    @include placeholder-style {
        color: #C4D0D6;
    }
}

.tp-6 {
    color: #4A4D51;
}

.dark .tp-6 {
    color: #D0D0E2;
}

.tp-7 {
    color: #4A4D51;

    &:hover,
    &.active {
        background: #F0F1FF;
    }
}

.dark .tp-7 {
    color: #D0D0E2;

    &:hover,
    &.active {
        background: #24243E;
    }
}

.tp-8 {
    background: #E8EBF5;
}

.dark .tp-8 {
    background: #2A2A43;
}

.tp-9 {
    color: #1D212F;
}

.dark .tp-9 {
    color: #D0D0E2;
}

.tp-10 {
    background: theme("colors.primary.100");
    color: #fff;
}

.tp-11 {
    color: #1D212F;
}

.dark .tp-11 {
    color: #EFEFEF;
}

.light {

    &.tp-1,
    .tp-1 {
        background: #fff;
    }

    &.tp-2,
    .tp-2 {
        border-color: #E8E8F7;
    }

    .tp-3 {
        color: #BBAFC7;
    }

    .tp-4 {
        color: #1D212F;

        &:hover {
            background: #F8F9FF;
            color: #6259CA;
        }
    }

    .tp-6 {
        color: #4A4D51;
    }

    .tp-7 {
        color: #4A4D51;

        &:hover,
        &.active {
            background: #F0F1FF;
        }
    }

    .tp-8 {
        background: #E8EBF5;
    }

    .tp-9 {
        color: #1D212F;
    }

    .tp-5 {
        color: #1D212F;

        @include placeholder-style {
            color: #BBAFCB;
        }
    }
}

.primary-c {

    &.tp-1,
    .tp-1 {
        background: theme("colors.primary.100");
    }

    .tp-7 {
        color: #fff;

        &:hover,
        &.active {
            background: theme("colors.primary.200");
        }
    }

    .tp-10 {
        background: #fff;
        color: theme("colors.primary.100");
    }

    &.tp-2,
    .tp-2 {
        border-color: #817AD5;
    }

    .tp-6 {
        color: #fff;
    }

    .tp-5 {
        color: #fff;

        @include placeholder-style {
            color: #D8DEF2;
        }
    }

    .tp-3 {
        color: #D9D7F2;
    }

    .tp-4 {
        color: #1D212F;

        &:hover {
            background: #F8F9FF;
            color: #6259CA;
        }
    }

    .searchbox .cats .dropdown {
        background: #fff;
        border-color: #E8E8F7;
    }

    .searchbox .result-box {
        background: #fff;
        border-color: #E8E8F7;

        b {
            border-color: #E8E8F7;
        }
    }

    .tp-11 {
        color: #1D212F;
    }
}

.tp-13 {
    background: #fff;

    .go-btn {
        background: #F6F6FF;
        border-width: 1px;
        border-style: solid;
        border-color: #E8E8F7;
        border-right-width: 0;
        color: #3F4B5B;
    }
}

.dark {
    .tp-13 {
        background: theme("colors.secondary");

        .go-btn {
            background: #24243E;
            color: #fff;
            border-color: #323244;
        }
    }
}

.light {
    .tp-13 {
        background: #fff;

        .go-btn {
            background: #F6F6FF;
            border-color: #E8E8F7;
            color: #3F4B5B;
        }
    }
}

.primary-c {
    .tp-13 {
        background: #fff;

        .go-btn {
            background: #F6F6FF;
            border-color: #E8E8F7;
            color: #3F4B5B;
        }

        .tp-1 {
            background: #fff;
        }

        &.tp-2,
        .tp-2 {
            border-color: #E8E8F7;
        }
    }
}

.tp-14 {
    border-color: #323244;
}

.light {
    .tp-14 {
        border-color: #E8E8F7;
    }
}

.primary-c {
    .tp-14 {
        border-color: #817AD5;
    }
}

.tp-15 {
    color: #6E6E7B;

    &:hover {
        color: #fff;
    }
}

.light .tp-15 {
    color: #8784A7;

    &:hover {
        color: #6259CA;
    }
}

.primary-c .tp-15 {
    color: #C0BDEA;

    &:hover {
        color: #fff;
    }
}

.active-c1 .tp-15 {
    color: #6259CA !important;
}

.active-c1 .tp-16,
.active-c1 .tp-17 {
    background: #E7EAF6;
}

.dark .active-c1 .tp-16,
.dark .active-c1 .tp-17,
.dark .dark .active-c1 .tp-16,
.dark .dark .active-c1 .tp-17 {
    background: #262642;
}

.tp-18 {
    color: #6E6E7B;

    &:hover,
    &.active {
        color: #fff;
    }
}

.light .tp-18 {
    color: #B2B2D0;

    &:hover,
    &.active {
        color: #6259CA;
    }
}

.primary-c .tp-18 {
    color: #C0BDEA;

    &:hover,
    &.active {
        color: #fff;
    }
}

.tp-19 {

    span,
    &::before,
    &::after {
        background: #2F395F;
    }
}

.dark {
    .tp-19 {

        span,
        &::before,
        &::after {
            background: #D0D0E2;
        }
    }
}

.light .tp-19 {

    span,
    &::before,
    &::after {
        background: #2F395F;
    }
}

.primary-c {
    .tp-19 {

        span,
        &::before,
        &::after {
            background: #D0D0E2;
        }
    }
}

.tp-20,
.primary-c .tp-20 {
    color: #fff;
}

.light .tp-20 {
    color: #6259CA;
}

.tp-21 {
    color: #170c6b;
}

.dark .tp-21 {
    color: #fff;
}

.breadcrumb {

    span,
    a {
        color: #8f9cc0;
    }

    a:hover {
        color: #38212F;
    }

    .active {
        color: #7787bc;
    }

    li::after {
        color: #8f9cc0;
    }
}

.dark .breadcrumb {

    span,
    a {
        color: #d0d0e2;
    }

    a:hover {
        color: #dedee9;
    }

    .active {
        color: #d0d0e2;
    }

    li::after {
        color: #d0d0e2;
    }
}

.tp-22 {
    background: #fff;
}

.dark .tp-22 {
    background: theme("colors.secondary");
}

.tp-23 {
    background: #fff;
    border: 1px solid #E8E8F7;

    &:focus {
        box-shadow: 0 0 2px 1px rgba(106, 77, 184, .3)
    }
}

.dark .tp-23 {
    background: #252542;
    border-color: #252542;
    color: #d0d0e2;
}

.tp-24 {
    .box {
        border: 1px solid #EBECF1;
        color: #8f9cc0;
    }

    .toggle-box {
        background: #fff;
        box-shadow: -8px 12px 18px 0 rgb(21 21 62 / 30%);

        li {

            a,
            span {
                color: #8f9cc0;
                transition: all .2s;

                &:hover {
                    background: #F1F2F9;
                    color: #6259ca;
                }
            }
        }
    }
}

.dark .tp-24 {
    .box {
        border-color: #252538;
        color: #fff;
    }

    .toggle-box {
        background: #0E0E23;
        box-shadow: -8px 12px 18px 0 #020208;
        border: 1px solid rgba(255, 255, 255, 0.12);

        li {

            a,
            span {
                color: #f0f0f9;

                &:hover {
                    background: #24243E;
                    color: #6259ca;
                }
            }
        }
    }
}

.options-holder {
    button {
        border: 1px solid #EBECF1;
        color: #8f9cc0;
    }
}

.dark .options-holder {
    button {
        border-color: #252538;
        color: #fff;
    }
}

.p-checkbox {
    span {
        border-color: #dedef5;
    }

    &.checked {
        span {
            border-color: theme("colors.primary.100") !important;
        }
    }

    &.x-type.checked span {
        border-color: theme("colors.red.500") !important;
    }
}

.dark .p-checkbox {
    span {
        border-color: #424263;
    }
}

.table-c1 {
    tbody {
        tr {
            border: 1px solid #EBECF1;

            &:nth-child(odd) {
                background: #F6F8FF;
            }
        }
    }

    .edit {
        color: theme("colors.primary.100");
    }
}

.dark .table-c1 {
    tbody {
        tr {
            border-color: #3F3F55;

            &:nth-child(odd) {
                background: #24243E;
            }
        }
    }

    .edit {
        color: #9B92FF
    }
}

.tp-25 {
    color: #1d212f;
}

.dark .tp-25 {
    color: #fdfeff;
}

.tp-26 {
    color: #8f8fb1;
}

.dark .tp-26 {
    color: #9e9eb3;
}

.pagination-c1 {
    border: 1px solid #EBEBF8;

    li {
        border-left: 1px solid #E8E8F7;

        .in-sec {
            color: #71719a;
        }

        &:hover {
            .in-sec {
                color: #0056B3;
                background: #F0F0FF;
            }
        }

        &.deactive {
            .in-sec {
                background: #fff;
                color: #a8afc7;
            }
        }

        &.active {
            .in-sec {
                background: theme("colors.primary.100");
                color: #fff;
            }
        }
    }
}

.dark .pagination-c1 {
    border-color: #3E3E4F;

    li {
        border-color: #3E3E4F;

        .in-sec {
            color: #fdfeff;
        }

        &:hover {
            .in-sec {
                color: #0056B3;
                background: #1A1A2E;
            }
        }

        &.deactive {
            .in-sec {
                background: #0E0E23;
                color: #d0d0e2;
                opacity: .5;
            }
        }

        &.active {
            .in-sec {
                background: theme("colors.primary.100");
                color: #fff;
            }
        }
    }
}

.modal {
    .wrapper {
        background: #fff;

        .title {
            color: #eee;
            border-bottom: 1px solid #eee;
        }
    }
}

.dark .modal {
    .wrapper {
        background: theme("colors.secondary");

        .title {
            color: #eee;
            border-color: #36366d;
        }
    }
}

.text-field {
    input {
        border: 1px solid #e7e7e7;

        &:focus {
            border-color: #8369cc;
            box-shadow: 0 0 1px 1px theme("colors.primary.100");
        }

        &:disabled {
            background: #fafafa;
        }
    }

    label {
        background: #fff;
        color: #777;
    }

    &:focus-within,
    &.has-val {
        label {
            color: #555;
        }
    }

    &.disabled {
        cursor: default;

        label {
            background: #fafafa;
            cursor: default;
        }
    }
}

.dark .text-field {
    input {
        background: theme("colors.secondary");
        border-color: #31314d;
        color: #fff;

        &:focus {
            background: transparent;
        }

        &:disabled {
            background: #131330;
        }
    }

    label {
        background: theme("colors.secondary");
        color: #a6a6c9;
    }

    &:focus-within,
    &.has-val {
        label {
            background: theme("colors.secondary");
            top: -.7rem;
            font-size: .813rem;
            color: #ccc;
        }
    }

    &.disabled {
        label {
            background: #131330;
        }
    }
}

.uploader {
    .file-field {
        border: 1px solid #e7e7e7;

        .file-icn {
            color: #ccc;
        }
    }
}

.fu-item {
    background: #f5f5f5;

    a {
        color: #555;

        &::after {
            background: #e6e6e6;
        }
    }

    button {
        color: theme("colors.red.400");

        &:hover {
            background: #e6e6e6;
        }
    }

    &.grabbing {
        cursor: grabbing;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    }

    .crop-btn {
        background: #e6e6e6;
        color: #555;
    }
}

.dark .fu-item {
    background: #262642;
    color: #27d9e6;

    a {
        color: #D0D0E2;

        &::after {
            background: #1d1d33;
        }
    }

    button {
        &:hover {
            background: #1d1d33;
        }
    }

    &.grabbing {
        box-shadow: 0 0 5px rgba(255, 255, 255, .2);
    }

    .crop-btn {
        background: #1d1d33;
        color: #D0D0E2;
    }
}

.fu-item-c1 {
    &.grabbing {
        cursor: grabbing;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    }
}

.dark .uploader {
    .file-field {
        border-color: #31314d;
        color: #fff;

        .file-icn {
            color: #6d6d92;
        }
    }
}

.select-c1 {
    border: 1px solid #dadada;

    span {
        background: #fff;
        color: #555;
    }
}

.dark .select-c1 {
    border-color: #31314d;

    select {
        background: theme("colors.secondary");
        color: #fff;
    }

    span {
        background: theme("colors.secondary");
        color: #ccc;
    }
}

.lightbox {
    background: #000;
}

.dark .lightbox {
    background: #fff;
}

.radio-c1 {
    font-size: .875rem;
    color: #1e293b;

    input {
        border: 1px solid #dadada;
    }
}

.dark .radio-c1 {
    color: #e5e7eb;

    input {
        border-color: #31314d;
    }
}

.radio-c1 {
    input:checked {
        border-color: theme("colors.primary.100");
    }
}

.tp-27 {
    color: #1e293b;
}

.dark .tp-27 {
    color: #e5e7eb;
}

.cat-holder {
    border: 1px solid #e7e7e7;

    .label {
        background: #fff;
    }
}

.dark .cat-holder {
    border-color: #31314d;

    .label {
        background: theme("colors.secondary");
    }
}

.unselect-c1 {
    color: #888;
}

.dark .unselect-c1 {
    color: #62628f;
}

.loading-c1 {
    background: #F1F2F9;
    z-index: 2;
}

.dark .loading-c1 {
    background: #24243E;
}

.textarea-c1 {
    border: 1px solid #e7e7e7;

    &:focus-within {
        border-color: #8369cc;
        box-shadow: 0 0 1px 1px theme("colors.primary.100");
    }

    &.editor-box:focus-within {
        border-color: #e7e7e7;
        box-shadow: none;
    }

    label.title {
        background: #fff;
        color: #777 !important;
    }

    &:focus-within,
    &.has-val {
        label.title {
            color: #555 !important;
        }
    }
}

.dark .textarea-c1 {
    background: theme("colors.secondary");
    border-color: #31314d;
    color: #fff;

    label.title {
        background: theme("colors.secondary");
        color: #a6a6c9;
    }

    &:focus-within,
    &.has-val {
        label.title {
            background: theme("colors.secondary");
            color: #ccc !important;
        }
    }

    &.editor-box:focus-within {
        border-color: #31314d !important;
        box-shadow: none;
    }

    textarea {
        background: theme("colors.secondary");
    }
}

.loading-c2 {
    background: rgba(255, 255, 255, .4);
}

.dark .loading-c2 {
    background: rgba(0, 0, 0, .4);
}

.section-title {
    &::before {
        border-bottom: 2px dotted #eee;
    }

    b {
        background: #fff;
        color: #555;
    }
}

.dark .section-title {
    &::before {
        border-color: #2f2f63
    }

    b {
        background: theme("colors.secondary");
        color: #a6a6c9;
    }
}

.tree-holder {
    ol {
        color: #555
    }
}

.dark .tree-holder {
    ol {
        color: #a6a6c9;
    }
}

.select-form-lightbox {
    background: rgba(255, 255, 255, .1);

    .m-block {
        border: 1px solid theme("colors.secondary");
    }
}

.dark .select-form-lightbox {
    background: rgba(0, 0, 0, .1);

    .m-block {
        border-color: rgba(255, 255, 255, .3);
    }
}

.control-item {
    .inner {
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
        background: #fff;

        button {
            background: #eee;
        }

        &.drag {
            background: #fbfbfb;
        }
    }
}

.dark .control-item {
    .inner {
        box-shadow: 0 0 5px rgba(255, 255, 255, .2);
        background: theme("colors.secondary");
        color: #a6a6c9;

        button {
            background: #2a2a50;
        }

        &.drag {
            background: #2e2e5f;
        }
    }
}

.tp-28 {
    color: #9393b1;
}

.dark .tp-28 {
    color: #d0d0e2;
}

.tp-29 {
    background: #fff;
}

.dark .tp-29 {
    background: #0E0E23;
}

.tp-30 {
    background: rgba(255, 255, 255, .6);
}

.dark .tp-30 {
    background: rgba(0, 0, 0, .6);
}

.confirm {
    .wrap-box {
        background: #fff;

        p {
            color: #333;
        }

        button {
            color: #fff;
        }
    }
}

.dark .confirm {
    .wrap-box {
        background: #0E0E23;

        p {
            color: #d0d0e2;
        }

        button {
            color: #fff;
        }
    }
}

.tickets {
    .send {
        box-shadow: 0 0 0 2px rgba(132, 196, 255, .7);

        &:focus-within {
            box-shadow: 0 0 0 2px rgb(132, 196, 255);
            border-color: rgb(132, 196, 255);
        }

        button {
            color: #333;

            &:hover {
                color: #555;
            }
        }
    }

    .ticket {
        border: 1px solid #abf1f1;

        &.hoverAction {
            &:hover {
                border-color: #777;
            }
        }

        .title {
            color: #333;

            &.hasBB {
                border-bottom: 1px solid #abf1f1;
            }
        }

        .time {
            .line {
                background: #abf1f1;
            }
        }

        p {
            color: #000;
        }
    }
}

.dark .tickets {
    .send {
        box-shadow: 0 0 0 2px rgba(132, 196, 255, .7);

        &:focus-within {
            box-shadow: 0 0 0 2px rgb(132, 196, 255);
            border-color: rgb(132, 196, 255);
        }

        textarea {
            color: #eee;
        }

        button {
            color: #eee;

            &:hover {
                color: #ccc;
            }
        }
    }

    .ticket {
        border: 1px solid theme("colors.slate.700");

        &.hoverAction {
            &:hover {
                border-color: #777;
            }
        }

        .title {
            color: #eee;

            &.hasBB {
                border-bottom: 1px solid theme("colors.slate.700");
            }
        }

        .time {
            .line {
                background: theme("colors.slate.700");
            }
        }

        p {
            color: #eee;
        }
    }
}

.order-detail {
    li {
        &::after {
            background: #ccc;
        }

        span {
            color: #979797;
        }
    }
}

.tiny-product {
    border: 1px solid #eee;
}

.dark .order-detail {
    li {
        &::after {
            background: theme("colors.slate.700");
        }

        span {
            color: #979797;
        }

        b {
            color: #eee;
        }
    }
}

.dark {
    .tiny-product {
        border-color: theme("colors.slate.700");
    }
}

.factor-sum {
    .unit {
        color: #979797;
    }

    ul {
        li {
            &:nth-child(odd) {
                background: #f1f1f1;
            }

            &.has-bb {
                border-bottom: 2px solid #d8d8d8;
            }

            &.has-bt {
                border-top: 2px solid #d8d8d8;
            }
        }
    }
}

.dark .factor-sum {
    color: #fff;

    .unit {
        color: #979797;
    }

    ul {
        li {
            &:nth-child(odd) {
                background: #262642;
            }

            &.has-bb {
                border-color: #4c4c79;
            }

            &.has-bt {
                border-color: #4c4c79;
            }
        }
    }
}

.search-select {
    .label-c {
        background: #fff;
        color: #555;
    }
}

.dark .search-select {
    .label-c {
        background: theme("colors.secondary");
        color: #a6a6c9;
    }
}

.price-table {
    .has-bg {
        background: #edffff;
    }

    .main-tbl {
        tr {
            &>td {
                color: #5F212F;
            }
        }

        thead {
            color: #3c4858;
        }
    }

    .title {
        background: theme("colors.blue.50");

        a {
            color: #5F212F;

            &:hover {
                color: #200b10;
            }
        }

        .discount {
            color: #fff;
            background: theme("colors.red.500");
        }
    }
}

.dark .price-table {
    .has-bg {
        background: #12122c;
    }

    .main-tbl {
        tr {
            &>td {
                color: #A4D0E2;
            }
        }

        thead {
            color: #A4D0E2;
        }
    }

    .title {
        background: #1c1c3f;

        a {
            color: #A4D0E2;

            &:hover {
                color: #8db4c4
            }
        }

        .discount {
            color: #fff;
            background: theme("colors.red.500");
        }
    }
}

.input-number {
    span {
        background: theme("colors.blue.100");
        color: theme("colors.blue.400");

        &:hover {
            background: theme("colors.blue.200");
        }
    }

    input {
        border: 1px solid theme("colors.blue.100");
    }
}

.dark .input-number {
    span {
        background: #1c1c3f;
        color: theme("colors.blue.400");

        &:hover {
            background: #282855;
        }
    }

    input {
        border: 1px solid #1c1c3f;
        background: transparent;
    }
}

.tp-31 {
    .box {
        background: #fff;
    }
}

.dark .tp-31 {
    .box {
        background: #fff;
    }
}

.tp-32 {
    border-color: #E8E8F7 !important;
}

.dark .tp-32 {
    border-color: #323244 !important;
}

.reply-textarea {
    border: 1px solid #e7e7e7;

    &:focus {
        border-color: #8369cc;
        box-shadow: 0 0 1px 1px theme("colors.primary.100");
    }
}

.dark .reply-textarea {
    background: theme("colors.secondary");
    border-color: #31314d;
    color: #fff;
}

.tp-34 {
    color: #1c273c;
}

.dark .tp-34 {
    color: #d0d0e2;
}

.tp-35 {
    color: #8f8fb1;
}

.dark .tp-35 {
    color: #d0d0e2;
}

.tp-36 {
    border: 1px solid #EBECF1;
    color: #5F212F;
    background: transparent;
    font-size: .875rem;
    border-radius: .25rem;
    padding: .25rem .5rem;

    option {
        color: #333;
    }
}

.dark .tp-36 {
    border-color: #252538;
    color: #fff;
}

.permissions {
    color: #333;

    .head {
        background: #fbfbfb;
    }
}

.dark .permissions {
    color: #f0f0f9;

    .head {
        background: #212149;
    }
}

.file-manager {
    .routes {
        li {
            color: #333;

            i {
                color: theme("colors.slate.400");
            }

            a {
                &:hover {
                    background: #fbfbfb;
                }
            }

            &.active {
                a {
                    background: #f1f1f1;
                }
            }
        }
    }

    .dirs {
        li {
            color: #333;

            &:hover {
                color: #555;
            }

            &::before {
                color: theme("colors.slate.400");
            }

            &.active {
                border: 1px solid theme("colors.gray.200");

                &::after {
                    background: #fff;
                }
            }
        }
    }

    .files {
        li {
            a {
                color: #333;

                &:hover {
                    color: #555;
                }
            }

            &:nth-child(even) {
                background: theme("colors.gray.50");
            }
        }
    }
}

.dark .file-manager {
    .routes {
        li {
            color: theme("colors.slate.300");

            i {
                color: theme("colors.slate.500");
            }

            a {
                &:hover {
                    background: theme("colors.slate.900");
                }
            }

            &.active {
                a {
                    background: theme("colors.slate.800");
                }
            }
        }
    }

    .dirs {
        li {
            color: theme("colors.slate.200");

            &:hover {
                color: theme("colors.slate.300");
            }

            &::before {
                color: theme("colors.slate.400");
            }

            &.active {
                border: 1px solid theme("colors.slate.700");

                &::after {
                    background: #0E0E23;
                }
            }
        }
    }

    .files {
        li {
            a {
                color: theme("colors.slate.300");

                &:hover {
                    color: theme("colors.slate.400");
                }
            }

            &:nth-child(even) {
                background: theme("colors.slate.800");
            }
        }
    }
}

.selectbox {
    &.c3 {
        .box {
            border: 1px solid theme("colors.gray.200");
            color: theme("colors.slate.900");
        }

        .toggle-box {
            background: #fff;

            li {
                &:hover {
                    background: #fbfbfb;
                }
            }
        }
    }
}

.dark .selectbox {
    &.c3 {
        .box {
            border-color: theme("colors.slate.700");
            color: theme("colors.slate.200");
        }

        .toggle-box {
            background: #0E0E23;

            li {
                color: theme("colors.slate.200");

                &:hover {
                    background: theme("colors.slate.900");
                }
            }
        }
    }
}

.state-box {
    li {
        &:nth-child(odd) {
            background: theme("colors.gray.50");
        }
    }
}

.dark .state-box {
    li {
        &:nth-child(odd) {
            background: theme("colors.slate.900");
        }
    }
}

.balance-holder {
    .m-block {
        background: #fff;

        &.hover-act {
            &:hover {
                box-shadow: 0 0 5px rgba(0, 0, 0, .2);
            }
        }
    }

    .box-title {
        color: #1c273c;
    }

    .icon-box {
        background: #EFEEFA;
        color: #6259CA;
    }

    li {
        &:first-child {
            border-bottom: 1px solid #eee;
        }

        span {
            color: theme("colors.gray.500");
        }
    }
}

.dark .balance-holder {
    .m-block {
        background: #0e0e23;

        &.hover-act {
            &:hover {
                box-shadow: 0 0 5px rgba(0, 174, 255, 0.3);
            }
        }
    }

    .box-title {
        color: theme("colors.slate.200");
    }

    .icon-box {
        background: #171634;
        color: #6259CA;
    }

    li {
        &:first-child {
            border-color: theme("colors.slate.700")
        }

        span {
            color: theme("colors.slate.400");
        }

        b {
            color: theme("colors.slate.200");
        }
    }
}

.financial-list {
    li {
        border-bottom: 1px solid #eee;

        span {
            color: theme("colors.gray.700");
        }
    }
}

.dark .financial-list {
    li {
        border-color: theme("colors.slate.800");

        span {
            color: theme("colors.slate.400");
        }

        b {
            color: theme("colors.slate.200");
        }
    }
}

.title-c2 {
    color: #555;
}

.dark .title-c2 {
    color: #ccc;
}

.icn-holder-c2 {
    color: theme("colors.primary.100");
}

.dark .icn-holder-c2 {
    color: theme("colors.slate.400");
}

.financial-state {
    li {
        border-bottom: 1px solid #eee;

        &:last-child {
            border: 0;
        }
    }
}

.dark .financial-state {
    li {
        border-color: #323244;
    }
}

.cl-result-box {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);

    li.ls-block {
        &:hover {
            background: #eee;
        }
    }
}

.dark .cl-result-box {
    background: theme("colors.slate.900");
    box-shadow: 0 0 5px rgba(255, 255, 255, .2);
    color: #fff;

    li.ls-block {
        &:hover {
            background: theme("colors.slate.800");
        }
    }
}

.tiny-order {
    &::after {
        background: theme("colors.slate.300");
    }
}

.dark .tiny-order {
    &::after {
        background: theme("colors.slate.700");
    }
}

.filesTbl {
    tr {
        border-bottom: .5rem solid #fff;
    }

    td,
    th {
        background: theme("colors.slate.100");
        border-left: .5rem solid #fff;
    }
}

.dark .filesTbl {
    tr {
        border-bottom: .5rem solid theme("colors.secondary");
    }

    td,
    th {
        background: theme("colors.slate.800");
        color: #fff;
        border-left: .5rem solid theme("colors.secondary");
    }
}